import Base from "./baseClientService";
import CourseService from "../services/course";
import EB3000Service from "./eb3000-service";

class ProgressService extends Base {
  static getPageCacheIndex(exp_id, chapter) {
    return `page_${exp_id}_${chapter}`;
  }

  static async getPageAnswers(exp_id, page) {
    const index = this.getPageCacheIndex(exp_id, page);

    return await this.getCachedOr(index, async (index) => {
      // dispatch GET request only if one of these pages:
      const pagesWithQuestions = ["experiment", "summary", "prep"];
      if (pagesWithQuestions.includes(page)) {
        try {
          const items = await this.GET(
            `/student/progress/experiment/${exp_id}/page/${page}/answers`
          );

          this.cache(index, items);

          return items;
        } catch (e) {
          console.error("Error fetching page answers: ", e.message);
          return false;
        }
      }
    });
  }

  /**
   * Fetches answer from page answer cache (or server, if not found)
   * @param {*} exp_id
   * @param {*} page
   * @param {*} question_id
   * @returns {object|false}
   */
  static async getAnswer(exp_id, page, question_id) {
    const pageAnswers = await this.getPageAnswers(exp_id, page);

    // TODO: do we need to do something in case of not finding the answer within page answers?

    if (!pageAnswers) return {};
    return pageAnswers[question_id] ? pageAnswers[question_id] : {};
  }

  /**
   * gets answers for given exp_id, chapter
   *
   * @param {*} course_experiment_id
   * @param {*} chapter
   * @param {*} answersObj
   */
  static async updateAnswers(
    course_experiment_id,
    chapter,
    answersObj, // = {'744':'0.1'} if this is empty object then there is no questions in the page
    checkEB3000 = true
  ) {
    // console.debug(
    //   "updateAnswers called with: ",
    //   course_experiment_id,
    //   chapter,
    //   answersObj
    // );
    try {
      if (checkEB3000) {
        if (!(await EB3000Service.checkIfConnected()))
          return this.Error("EB3000 is not connected!");
      }
      const result = await this.POST(
        `/student/progress/experiment/${course_experiment_id}/page/${chapter}/answers`,
        { answers: answersObj }
      );
      console.debug(
        "INSIDE updateAnswers in Services/progress - items: ",
        result
      );

      // get page cache index
      const index = this.getPageCacheIndex(course_experiment_id, chapter);

      // get existing answers, if any
      const existingAnswers = this.getCached(index, {});

      // get only the answers, skip the flags.
      const { answers, message, errorLevel } = result;

      // if message came and its errorLevel is error (or empty - which means error by default),
      // reset cache, cause if we don't, the next time user opens a page with questions,
      // previous answers will be there.
      if ((message && !errorLevel) || (message && errorLevel === "error")) {
        console.debug(
          "updateAnswers: Clearing progress cache because error message was received"
        );
        this.clearCache();
      } else {
        // no message received
        console.debug(
          "updateAnswers: existingAnswers, index, result:\n",
          existingAnswers,
          index,
          result
        );

        // overwrite/add fetched answers
        this.cache(index, { ...existingAnswers, ...answers });

        console.debug("updateAnswers: has cached:", this.getCached(index));
      }

      return result;
    } catch (e) {
      console.error("Error posting page answers: ", e.message);
      return this.Error(e.message);
    }

    //this.cache(this.getExperimentCacheIndex(course_experiment_id), experiments);
  }

  static async getExperimentProgress(exp_id) {
    try {
      const expProgress = await this.GET(
        `/student/progress/experiment/${exp_id}`
      );

      return expProgress;
    } catch (err) {
      console.debug("Error fetching experiment progress", err);
      return false;
    }
  }

  static async getCourseProgress(course_id) {
    try {
      const courseProgress = await this.GET(
        `/student/progress/course/${course_id}`
      );

      return courseProgress;
    } catch (err) {
      console.debug("Error fetching course progress", err);
      return false;
    }
  }

  static async resetCourseProgress(id) {
    try {
      const result = await this.POST(`/student/progress/course/${id}/reset`);
      this.clearCache();
      return result;
    } catch (e) {
      this.Error("Error reseting course progress: ", e.message);
      return false;
    }
  }

  static async resetLastExperiment(id) {
    try {
      const result = await this.POST(
        `/student/progress/course/${id}/reset-last-experiment`
      );
      this.clearCache();
      return result;
    } catch (e) {
      this.Error("Error reseting course progress: ", e.message);
      return false;
    }
  }

  static async getCourseStats(course_id) {
    try {
      const stats = await this.GET(
        `/student/progress/course/${course_id}/stats`
      );
      return stats;
    } catch (err) {
      console.debug("Error fetching course progress", err);
      return false;
    }
  }

  static async autofinishPage(experiment_id, page_slug) {
    try {
      const result = await this.POST(
        `/student/progress/experiment/${experiment_id}/page/${page_slug}/autofinish`
      );
      // reset cache to fetch new data from server
      this.clearCache();
      return result;
    } catch (err) {
      console.debug("Error auto-finishing page progress:", err);
      return this.Error(
        err.message || "Unknown error when auto-finishing the page"
      );
    }
  }

  static async resetPageProgress(experiment_id) {
    try {
      const result = await this.POST(
        // `/student/progress/experiment/${experiment_id}/page/${page_slug}/reset`
        `/student/progress/experiment/${experiment_id}/reset-chapter`
      );
      // reset cache to fetch new data from server
      this.clearCache();
      return result;
    } catch (err) {
      console.debug("Error resetting progress", err);
      return this.Error(
        err.message || "Unknown error when auto-finishing the page"
      );
    }
  }
}
Base.extend(ProgressService);

export default ProgressService;
