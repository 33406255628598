import React from "react";
import Parser from "../../../../../shared-components/Parser";
import Spinner from "../../../../../shared-components/Parser/Spinner";

// import CourseService from '../../../../../services/course';
import ProgressService from "../../../../../services/progress";
import { connect } from "react-redux";
import { showMessage } from "../../../../../redux/notificationActions";
import { showModal } from "../../../../../redux/modalActions";
import { Button } from "@material-ui/core";
import { Delete, SkipNext } from "@material-ui/icons";
import AsyncButton from "../../../../../shared-components/Parser/AsyncButton";
import AuthService from "../../../../../services/AuthService";

class ExperimentCycle extends React.Component {
  state = {
    status: null,
    filtered: [],
    currentHtml: null,
    currentExperiment: null,
  };

  componentDidMount() {
    console.log("ExperimentCycle - this.props: ", this.props);
    this.populateState();
  }

  componentDidUpdate(prevProps) {
    console.log(
      "ExperimentCycle componentDidUpdate prev props: \n",
      prevProps,
      "\ncurrent props:",
      this.props
    );
    const { chapter: old_chapter, current_experiment_id: old_id , language: old_language, experiments: old_experiments } = prevProps;
    const { chapter, current_experiment_id: id, language , experiments } = this.props;
    if (old_chapter !== chapter || old_id !== id || old_language !== language || JSON.stringify(experiments) !== JSON.stringify(old_experiments) ) {
      console.log("ExperimentCycle populating state");
      this.populateState();
    }
  }

  onQuestionChange = async (answersObj) => {
    const { currentExperiment } = this.state;
    const {
      // course_id,
      chapter,
      pageIsDone,
      onShowMessage,
      onShowModal,
      flagsHandler,
    } = this.props;

    const { allowNoHardwareExperiment, userIsTester } = this.props;

    // if page is done, don't do anything and show warning message
    if (pageIsDone) {
      onShowMessage(
        "This page is finished, your changes will not be saved",
        "warning"
      );
      return false;
    }

    // if it's Experiment - check if EB3000 is connected
    const checkEB3000 =
      chapter === "experiment" &&
      // also check that if the user is tester - no-hardware experiment is not allowed
      !(userIsTester && allowNoHardwareExperiment);

    try {
      const result = await ProgressService.updateAnswers(
        currentExperiment.course_experiment_id,
        chapter,
        answersObj,
        checkEB3000
      );

      if (!result)
        onShowMessage(
          ProgressService.error ||
            "Unknown error, please try reloading the page",
          "error"
        );

      const {
        courseStatusChange,
        experimentStatusChanged,
        pageStatusChanged,
        message,
      } = result;

      // check if there is an error coming back from server
      if (message) {
        // auto-size class makes it shrink to content
        onShowModal("Attention!", message, [{ type: "ok", text: "close" }], {
          className: "auto-size",
        });
      }

      flagsHandler(
        courseStatusChange,
        experimentStatusChanged,
        pageStatusChanged
      );

      //return answers to parser
      return result.answers;
    } catch (err) {
      console.debug(
        "Error inside onQuestionChange inside ExperimentCycle",
        err
      );
      onShowMessage(
        err.message || "Unknown error, please try reloading the page",
        "error"
      );
    }
  };

  /**
   * Select current page and populate state with its data
   */
  async populateState() {
    // show spinner before anything
    this.setState({ currentExperiment: null }, async () => {

      const { subDivisions, experiments, chapter, exp_id } = this.props;
  
      const current_experiment_id = exp_id || experiments[0].course_experiment_id;
  
      const currSub =
        subDivisions.find((s) => chapter === s.id) || subDivisions[0];
  
      const currentHtmlIndexes = currSub.index; // an array of indexes whose html has to be glued together.
  
      // selected experiment data
      const currentExperiment =
        experiments.find(
          (e) =>
            parseInt(e.course_experiment_id) === parseInt(current_experiment_id)
        ) || {};
  
      // find needed html within selected experiment using indexes
      const currentHtml = currentHtmlIndexes.reduce(
        (res, ind) => `${res}${currentExperiment[ind]}`,
        ""
      );
  
      // const experimentProgress = await ProgressService.getExperimentProgress(
      //   exp_id
      // );
  
      const pageAnswers = await ProgressService.getPageAnswers(
        current_experiment_id,
        chapter
      );
  
      // filtered questions - only the ones that are actually present on this page
      let filtered = [];
      if (pageAnswers) {
        const pageQuestionsIds = Object.keys(pageAnswers).map((i) => parseInt(i));
        // console.debug("ExpCycle.populateState filtering questions:\n", currentExperiment.questions, "\nwith:\n",pageQuestionsIds );
  
        filtered = currentExperiment.questions.filter((q) =>
          pageQuestionsIds.includes(q.question_id)
        );
        // for (let i = 0; i < currentExperiment.questions.length; i++) {
        //   if (
        //     pageQuestionsIds.includes(
        //       currentExperiment.questions[i].question_id.toString()
        //     )
        //   ) {
        //     filtered = [...filtered, currentExperiment.questions[i]];
        //   }
        // }
      }
  
      this.setState({
        currentHtml,
        currentExperiment,
        filtered,
        pageAnswers,
      });
    });

  }


  onParserErrors = (errorReport) => {
    const { chapter } = this.props;

    const { currentExperiment } = this.state;
    // TODO: add it to queue in redux and use
    AuthService.reportError(
      `Parsing errors in page '${chapter}' of experiment ${currentExperiment.course_experiment_id} (${currentExperiment.title}) `,
      errorReport,
      "parser error"
    );
  };

  render() {
    const { currentHtml, currentExperiment, filtered, pageAnswers } =
      this.state;

    const {
      // user,
      userIsTester,
      current_page,
      chapter,
      pageIsDone,
      resetPageProgress,
      autofinishPage,
      // allowNoHardwareExperiment,
    } = this.props;

    // spinner is shown every time populateState is called
    if (!currentExperiment) return <Spinner />;

    // const userIsTester =
    //   user &&
    //   Array.isArray(user.credentials) &&
    //   user.credentials.includes("tester");
    const pageHasQuestions = Boolean(pageAnswers);
    const pageIsCurrent = chapter && current_page === chapter;

    return (
      <>
        {
          // tester tools - show only if:
          userIsTester &&
            pageHasQuestions &&
            pageIsCurrent &&
            // page is not done yet
            !pageIsDone && (
              <div className="tester-tools">
                <AsyncButton
                  variant="contained"
                  color="primary"
                  size="small"
                  icon={<SkipNext />}
                  // onClick={this.autofinishPage.bind(this)}
                  onClick={autofinishPage}
                >
                  Auto-finish page
                </AsyncButton>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<Delete />}
                  // onClick={this.resetPageProgress.bind(this)}
                  onClick={resetPageProgress}
                >
                  reset page
                </Button>
              </div>
            )
        }

        <Parser
          html={currentHtml}
          onQuestionChange={this.onQuestionChange.bind(this)}
          questions={pageAnswers}
          allQuestions={filtered}
          onErrors={this.onParserErrors}
        />
      </>
    );
  }
}

// const stateToProps = (state) => {
//   const { user, allowNoHardwareExperiment } = state.root;

//   return { user, allowNoHardwareExperiment };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
    onShowModal: (header, text, buttons, modalProps) =>
      dispatch(showModal(header, text, buttons, modalProps)),
  };
};

// export default connect(stateToProps, mapDispatchToProps)(ExperimentCycle);
export default connect(null, mapDispatchToProps)(ExperimentCycle);
