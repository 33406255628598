import { CircularProgress, Container, Typography } from '@material-ui/core'
import React from 'react'


export default props => {
    return(
        <div className="spinner-container">
            <CircularProgress color="primary" />{props.text && <Typography color={props.color || "primary"}>{props.text}</Typography>}
        </div>
    )
}