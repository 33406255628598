import {
  Container,
  Typography,
  Tabs,
  Tab,
  // Breadcrumbs,
} from "@material-ui/core";

import AutoBreadcrumbs from "../../../shared-components/template/AutoBreadcrumbs";
import React from "react";
import Spinner from "../../template/spinner";
import Service from "../../../services/course";
import { Link } from "react-router-dom";

import "./courses.scss";

import { courseSubroutes as routes } from "../../../data/routes";
import experiment_pages from "../../../data/experiment_pages.json";
import { buildLink } from "../../../utils/functions";
import { Home } from "@material-ui/icons";
import Error from "../../template/Error";
import WithTranslations from "../../WithTranslations";
import Page404 from "../../template/Page404";
import courseNavigationActions from "../../../redux/courseNavigationActions";
import { connect } from "react-redux";
import ProgramService from "../../../services/program";

class Course extends React.Component {
  state = {
    course: null,
    fetchError: null,
    tabsValue: 0,
    ChildComponent: null,
    program: null,
  };
  componentDidMount() {
    const { id, slug = "preface", prog_id } = this.props.match.params;
    console.log("Course - this.props: ", this.props);
    this.fetchData(id);
    this.setSubroute(slug);
    this.fetchProgram(prog_id);
    this.props.fetchTranslations([
      "Formulas",
      "Preface",
      "Course",
      "Progress",
      "Program",
      "Unknown course",
      "Objectives",
      "Discussion",
      "Preparation Questions",
      "Experiment",
      "Summary Questions",
    ]);
    const { onCourseNavigationActions } = this.props;
    onCourseNavigationActions(prog_id, id);
  }

  componentDidUpdate(prevProps) {
    console.debug(
      "Course index did update with:\n",
      this.props,
      "\nprevious props: \n",
      prevProps
    );
    const { id, slug = "" } = this.props.match.params;
    const { id: old_id, slug: old_slug = "" } = prevProps.match.params;
    const { _language } = this.props;
    const { _language: old_language } = prevProps;
    console.debug(
      "Course index did update old and new language:",
      old_language,
      _language
    );
    console.debug("Course index did update old and new slug:", old_slug, slug);
    console.debug("Course index did update old and new id:", old_id, id);
    if (id !== old_id || _language !== old_language) this.fetchData(id);
    if (slug !== old_slug) this.setSubroute(slug);
  }
  // fetch program data
  async fetchProgram(id) {
    const programs = await ProgramService.getPrograms();
    console.log("course programs", programs);
    const program = programs.find((i) => i.program_id === parseInt(id));
    this.setState({ program });
  }
  // subrouting tabs
  setSubroute = (slug) => {
    const ind = routes.findIndex((i) => i.link === slug);

    if (!routes[ind]) {
      this.setState({ tabsValue: 0 });
      return;
    }

    if (ind !== this.state.tabsValue) {
      // set tab value if not set yet
      this.setState({ tabsValue: ind });
    }
  };

  async fetchData(id) {
    console.debug("Course index fetchData called with", id);
    if (id) {
      const course = await Service.getCourse(id);
      const fetchError = Service.error;
      this.setState({ course, fetchError });
    } else {
      console.debug("Not found id:", id);
      this.setState({ course: false, fetchError: "Course ID wasn't found" });
    }
  }

  setTab = (tabsValue) => this.setState({ tabsValue });

  render() {
    const {
      course,
      tabsValue,
      // ChildComponent,
      fetchError,
      program,
    } = this.state;
    const {
      id,
      slug = "preface",
      exp_id,
      chapter,
      prog_id,
      organization,
    } = this.props.match.params;

    console.debug("Courses index match.params:\n", this.props.match.params);

    const { _t } = this.props;

    // don't proceede if no course is set
    if (course === null) return <Spinner />;

    // Build breadcrumbs
    // TODO: get organization/program names

    // get course slug data
    const slugData = routes.find((s) => s.link === slug) || {
      title: "Error",
      link: slug,
      component: Page404,
    };

    console.debug("Breadcrumbs course:", course);

    let breadcrumbs = [
      { to: buildLink({ organization }), icon: <Home /> },
      {
        to: buildLink({ organization, program: prog_id }),
        text: program ? program.title : _t("Program"),
      },
      // {to: buildLink({organization, program: prog_id, course: id }), text: course.course_code + ": " + course.title },
      {
        to: buildLink({ organization, program: prog_id, course: id }),
        text: course ? course.course_code : _t("Unknown course"),
      },
      {
        to: buildLink({ organization, program: prog_id, course: id, slug }),
        text: _t(slugData.title),
      },
    ];

    // select child component
    const ChildComponent = slugData.component;

    // if it's experiment and chapter is set, add chapter title
    if (slug === "experiment" && chapter) {
      const chapterData = experiment_pages[chapter] || { title: "Error" };
      breadcrumbs = [
        ...breadcrumbs,
        {
          to: buildLink({
            organization,
            program: prog_id,
            course: id,
            slug,
            chapter,
          }),
          text: _t(chapterData.title),
        },
      ];
    }

    console.debug("Courses index course:", course);

    if (course === false)
      return (
        <Error
          title="Error fetching course data"
          error={fetchError}
          breadcrumbsItems={breadcrumbs}
        />
      );

    console.debug(
      "Courses index: render HTML. Id:",
      id,
      ", chapter: ",
      chapter
    );

    return (
      <Container key={`course-component-${id}`}>
        <AutoBreadcrumbs items={breadcrumbs} />
        <Typography variant="h1" color="primary">
          {course.course_code} {course.title}
        </Typography>
        <Typography variant="body1">{course.description}</Typography>
        <Tabs className="course-tabs" value={tabsValue} variant="scrollable">
          {routes.map((t, ind) => (
            <Tab
              key={`tab-${ind}`}
              component={Link}
              // to={`/program/${prog_id}/course/${id}${
              //   t.link ? `/${t.link}` : ''
              // }`}
              to={buildLink({
                organization,
                program: prog_id,
                course: id,
                slug: t.link,
              })}
              label={_t(t.title)}
              index={ind}
            />
          ))}
        </Tabs>
        {
          // render child component if slug is defined (it's probably a redundant check, but just in case)
          slug && (
            <ChildComponent
              key={`course-route-${slug}`}
              {...{
                organization,
                prog_id,
                course,
                exp_id,
                chapter,
              }}
              // organization={organization}
              // prog_id={prog_id}
              // course={course}
              // exp_id={exp_id}
              // chapter={chapter}
            />
          )
        }
      </Container>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  onCourseNavigationActions: (program_id, current_course) =>
    dispatch(courseNavigationActions(program_id, current_course)),
});

export default connect(null, mapDispatchToProps)(WithTranslations(Course));
