import ActionTypes from './actionTypes';
import store from './store';


export default (program_id, current_course) => {
  const user_id=store.getState().root.user.id;
    return (dispatch) => {
      dispatch({
        type: ActionTypes.SET_CURRENT_PROGRAM_COURSE,
        payload: { program_id, current_course,user_id }
      });
    };
  };