import React from 'react'
import { Container, Typography } from '@material-ui/core'


class Catalog extends React.Component{

    render(){
        return(
            <Container>
                <Typography variant="h1" color="primary">Catalog</Typography>
            </Container>
        )
    }
}

export default Catalog