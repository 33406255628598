import { Button, Typography } from '@material-ui/core'
import { PictureAsPdf } from '@material-ui/icons'
import WithTranslations from '../../../../WithTranslations';
import React from 'react'


class FormulaSheet extends React.Component{


    componentDidMount(){
        this.props.fetchTranslations([
            "Formulas",
            "Download formula sheet"
        ])
    }

    render(){
        
        const {
            _t,
            course = {}
        } = this.props;
        
        // console.debug("formulas course", course);

        const {formulas} = course;
        return(<div className="formula-sheet">
            <Typography variant="h3" color="primary">{_t("Formulas")}</Typography>
            <Button component={"a"} target="_blank" href={formulas ? formulas : null} variant="contained" color="primary" disabled={!Boolean(formulas)}><PictureAsPdf/> {_t('Download formula sheet')}</Button>
        </div>)
    }
}

export default WithTranslations( FormulaSheet ) 