import React from "react";
import { connect } from "react-redux";
import { Modal, Typography, Button } from "@material-ui/core";
import { closeModal } from "../../../redux/modalActions";
import { Link } from "react-router-dom";
import "./reduxModal.scss";

class ReduxModal extends React.Component {
  // function to render a button given object {type: 'ok/url', text: String, ?url: String }
  makeButton(data, index) {
    if (data.type === "url") {
      return (
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={data.url}
          onClick={this.props.onCloseModal}
          key={`modal-btn-${index}`}
        >
          {data.text}
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={(ev) => {
            this.props.onCloseModal();
            if (data.onClick) data.onClick(ev);
          }}
          key={`modal-btn-${index}`}
          {...{ ...(data.buttonProps || {}) }}
        >
          {data.text}
        </Button>
      );
    }
  }

  render() {
    //console.log('ReduxModal - this.props: ', this.props);
    const { content, onCloseModal } = this.props;

    // if no content, use empty object
    const {
      header,
      text,
      buttons = [],
      modalProps = {}
    } = content || {};

    const {className = "", otherModalProps}  = modalProps;

    const body = (
      <div className={`modal-container flex column align-items-stretch ${className}`}>
        <div item className="modal-header">
          {header}
        </div>
        <Typography className="modal-text" dangerouslySetInnerHTML={{ __html: text }}></Typography>
        {Array.isArray(buttons) && (
          <div className={`modal-buttons flex row align-items-center justify-content-center`}>
            {buttons.map((btn, ind) => this.makeButton(btn, ind))}
          </div>
        )}
      </div>
    );

    return (
      <div>
        <Modal
          open={this.props.showModal}
          onClose={onCloseModal}
          {...modalProps}
        >
          {this.props.showModal ? body : <></>}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showModal: state.ui.showModal,
    content: state.ui.modalContents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseModal: () => dispatch(closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReduxModal);
