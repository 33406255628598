import React from 'react';
import { BrowserRouter, 
  Route
  // Link 
} from 'react-router-dom';
import { Routes as Switch } from 'react-router';
import RouteComponent from './RouteComponent';
import './style.scss';
import routes from '../../data/non-authorized-routes';
import Navbar from '../template/Navbar';
import { connect } from 'react-redux';
import WithTranslations from '../WithTranslations';
import RouteScrollingComponent from './RouteScrollingComponent';
import LanguageSwitch from './language-switch';
// import CenterLogo from '../template/Navbar/CenterLogo';
// import { Person } from '@material-ui/icons';


const {
  REACT_APP_BASE_URL
} = process.env;

class NonAuthorizedMain extends React.Component {
  componentDidMount() {
    this.props.fetchTranslations(['Login']);
  }
  render() {
    const { location, _t } = this.props;

    const navbarProps = {
      location,
      LeftComponent: <div className='top-left'></div>,
      RightComponent: <div className='top-right'><LanguageSwitch/></div>,
      // MiddleComponent: <CenterLogo showLink={false} />
    };

    return (
      <BrowserRouter basename={REACT_APP_BASE_URL}>
        <Navbar {...navbarProps} />
        <div className={`main`}>
          <Switch>
            {routes.map((route, ind) => {
              const {component, ...rest} = route
              return (               
              //  <Route {...{ ...rest, key: `route-${ind}`, element: <RouteScrollingComponent {...{location, component}} /> }} />
              //  <Route {...{ ...rest, key: `route-${ind}`, element: <RouteScrollingComponent {...{location, component}} />  }} />
               <Route {...{ ...rest, key: `route-${ind}`, element: <RouteScrollingComponent {...{location, component}} />  }} />
             )})
            }
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}
const stateToProps = (state) => {
  const { user } = state.root;
  return { user };
};

export default connect(stateToProps, null)(WithTranslations(NonAuthorizedMain));
