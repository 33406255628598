import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import root from './reducers';
import ui from './uiReducers';
import {reducers} from '@bit/ses-education.ses-courses-shared-components.notifications'
import courseNavigation from './courseNavigation';

const store = createStore(
  combineReducers({ root, ui, reducers, courseNavigation }),
  compose(applyMiddleware(thunk))
);
export default store;
