/**
 * cudos to https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
 * @param {*} string
 */
export function slugify(string) {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

/**
 * Replaces substrings wrapped with %..% with corresponding strings from items{} object
 * E.g.
 * items: { %name%: "Me", %age%: 12 }
 * str: "I am %name%" and I'm %age% years old. That's %name%."
 * strFormat(str, items) >>> "I am Me and I'm 12 years old. that's Me."
 * @param {string} str string to search/replace within
 * @param {object} items object of { '%str1%': string1, '%str%': string2, ...  }
 */
export function strFormat(str, items) {
  return str.replace(/%\w+%/g, (key) => {
    return items[key] || key;
  });
}

/**
 * Kudos to https://stackoverflow.com/questions/19269545/how-to-get-n-no-elements-randomly-from-an-array/38571132#comment74054264_38571132
 * @param {*} array
 * @param {*} num
 */
export const randomFromArray = (array, num) =>
  shuffleArray(array).slice(0, num);
// array.sort(() => 0.5 - Math.random()).slice(0, num);

export const shuffleArray = (array) => array.sort(() => 0.5 - Math.random());

/**
 * Loads an external script asynchronously and optionally executes a callback once it's done.
 * Kudos to https://stackoverflow.com/a/53396827/3770223
 * @param {*} url
 * @param {*} callback
 */
export function loadScript(url, callback = null) {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  if (callback && typeof callback === "function")
    script.onload = () => callback();

  document.body.appendChild(script);
}

/**
 * Replace anything within %..% with correponding eement from passed array
 * Kudos to https://stackoverflow.com/questions/7975005/format-a-javascript-string-using-placeholders-and-an-object-of-substitutions#comment100792501_35187109
 * @param {string} message
 * @param {string[]} values
 */
export function textFormat(message, values) {
  let i = 0;
  if (message)
    return message.replace(/%\w+%/g, (match, idx) => {
      return values[i++];
    });
  else {
    console.warn("textFormat: passed value is not a string, null returned.");
    return null;
  }
}

/**
 * Scrolls to given element with given offset in pixels
 */
export function scrollToElement(
  el,
  yOffset = 0,
  options = { behavior: "smooth" }
) {
  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ ...options, top: y });
}

export function removePrefix(str, prefix) {
  return str.indexOf(prefix) === 0 ? str.substr(prefix.length) : str;
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

/**
 * Builds link using passed data
 * Acceptet URL nodes:
 * [ program, course, slug, experiment, chapter ]
 * @param {*} data
 */
export function buildLink(data) {
  let { organization, program, course, slug, experiment, chapter } = data || {};

  /* console.debug( "buildLink called with:",  organization, program,
    course,
    slug,
    experiment,
    chapter) */

  // impose hierarchy
  program = organization ? program : null;
  course = program ? course : null;
  slug = course ? slug : null;
  // experiment only works when slug is 'experiment'
  experiment = slug === "experiment" ? experiment : null;
  // chapter only works when experiment is set
  chapter = experiment ? chapter : null;

  return `/${organization}${program ? `/program/${program}` : ""}${
    course ? `/course/${course}` : ""
  }${slug ? `/${slug}` : ""}${experiment ? `/${experiment}` : ""}${
    chapter ? `/${chapter}` : ""
  }`;
}

export function scrollToHash(hash, yOffset = 0, behavior = "smooth") {
  console.debug("scrollToHash", hash);
  // scroll to given hash
  const el = document.getElementById(hash);

  if (el) {
    try {
      // calculate offset position to scroll to
      const pos = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

      // scroll
      console.debug("scrollToHash", pos);
      window.scrollTo({ top: pos, behavior });
    } catch (e) {
      console.debug("scrollToHash Error scrolling to hash", e);
    }
  }
}
