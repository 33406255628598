import React from 'react'
import {ReactComponent as CenterLogoSvg } from './images/ses-stlab-logo-blue.svg';
import {Link} from 'react-router-dom';

class CenterLogo extends React.Component{

    render(){
        const { homeLink = "/about", showLink= true } = this.props
        return( 
            <>
            { 
            showLink && <Link className="center-logo" to={homeLink}> 
                    <CenterLogoSvg />            
                </Link> 
            }

            { 
            !showLink && <div className="center-logo">
                <CenterLogoSvg /> 
            </div>
            }
            </>
        )
    }
}

export default CenterLogo