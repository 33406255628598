import React from "react";
import {
  Modal,
  Grid,
  Typography,
  Container,
  Button,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Home } from "@material-ui/icons";
import Service from "../../services/AuthService";
import { connect } from "react-redux";
import { showMessage } from "../../redux/notificationActions";
import { toggleNoHardware } from "../../redux/userActions";
import Spinner from "../../components/template/spinner";
import { Link } from "react-router-dom";
import "./styles.scss";
import AutoBreadcrumbs from "../../shared-components/template/AutoBreadcrumbs";
import PasswordChangeDialog from "../../shared-components/template/PasswordChangeDialog";
import TesterTools from "./tester-tools";
import WithTranslations from "../WithTranslations";

const DetailsRow = (props) => (
  <div className="details-row">
    <span>{props.title}</span>
    <span>{props.value}</span>
  </div>
);

class UserProfile extends React.Component {
  state = {
    password: "",
    password2: "",
    currentUser: this.props.reduxUser,
    editPasswordModalOpen: false,
    error: null,
  };

  componentDidMount() {
    this.props.fetchTranslations([
      "User profile",
      "User ID",
      "First Name",
      "Last Name",
      "Email",
      "Back",
      "Change Password",
    ]);
    console.log("USER: ", this.state.currentUser);
  }

  changePasswordHandler = async (password) => {
    // const { currentUser } = this.state;
    // TODO:
    // 1. Send password change to server
    const result = await Service.changePassword(password);
    if (!result) {
      this.props.onShowMessage(Service.error, "error");
      return;
    }
    this.closeEditPasswordModal();
  };

  closeEditPasswordModal = () => {
    this.setState({
      editPasswordModalOpen: false,
    });
  };

  render() {
    const { toggleHardware, reduxUser, allowNoHardwareExperiment, _t } = this.props;

    const { currentUser, editPasswordModalOpen } = this.state;
    console.debug("allowNoHardwareExperiment", allowNoHardwareExperiment)

    const { credentials } = reduxUser;
    const isTester = Array.isArray(credentials)
      ? credentials.includes("tester")
      : false;

    return (
      <Container className="user-profile">
        <AutoBreadcrumbs
          items={[
            { icon: <Home />, to: "/" },
            { text: "Profile", link: "/profile" },
          ]}
        />
        {currentUser ? (
          <>
            <Typography variant="h1" color="primary">
              {_t("User profile")}
            </Typography>
            <Grid container className="details-container" spacing={4}>
              <Grid
                item
                lg={3}
                md={4}
                sm={4}
                xs={12}
                className="image-container"
              >
                <img
                  src={currentUser.image || `${process.env.PUBLIC_URL}/images/userPlaceholder.jpg`}
                  alt="user-placeholder"
                  className="image"
                ></img>
              </Grid>
              <Grid item lg={6} md={5} sm={5} xs={12} className="details-text">
                {[
                  { title: _t("User ID"), value: currentUser.id },
                  { title: _t("First Name"), value: currentUser.name },
                  { title: _t("Last Name"), value: currentUser.last_name },
                  { title: _t("Email"), value: currentUser.email },
                ].map((detail) => (
                  <DetailsRow {...detail} />
                ))}
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={3}
                xs={12}
                className="buttons-container"
              >
                <Button
                  variant="contained"
                  color="primary"
                  // component={Link}
                  // to={"/organizations"}
                  onClick={()=>{
                    this.props.navigate(
                      //check if exist last page in our website if not go to / else go to last page (-1)
                      this.props.location.key !== "default" ? -1 : '/');
                  }}
                >
                  {_t("Back")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      editPasswordModalOpen: true,
                    })
                  }
                  color="secondary"
                >
                  {_t("Change Password")}
                </Button>
              </Grid>
              {isTester && (
                <Grid item>
                  <TesterTools
                  {...{
                    reduxUser,
                    toggleHardware,
                    allowNoHardwareExperiment
                  }}
                  />
                  {/* <FormGroup>
                    <FormControlLabel
                    label="EB3000 connection required: "
                    labelPlacement="start"
                      control={
                        <Switch
                          onClick={() => toggleHardware()}
                          checked={ !allowNoHardwareExperiment}
                          />
                      }
                    />
                  </FormGroup> */}
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Spinner />
        )}
        <PasswordChangeDialog
          open={editPasswordModalOpen}
          onSubmit={this.changePasswordHandler}
          onClose={this.closeEditPasswordModal}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
    toggleHardware: () => dispatch(toggleNoHardware()),
  };
};

const stateToProps = (state) => ({
  reduxUser: state.root.user,
  allowNoHardwareExperiment: state.root.allowNoHardwareExperiment,
});

export default WithTranslations (connect(stateToProps, mapDispatchToProps)(UserProfile));
