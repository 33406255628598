import React from "react";
import Spinner from "../../../../template/spinner";
import CourseService from "../../../../../services/course";
import ProgressService from "../../../../../services/progress";
import { Link, Navigate as Redirect } from "react-router-dom";
import ExpAccordion from "./ExpAccordion";
import "..//../courses.scss";
import {
  Typography,
  // Container,
  Grid,
  List,
  ListItem,
  Button,
  CircularProgress,
} from "@material-ui/core";
import ExperimentCycle from "./ExperimentCycle";
import experiment_pages from "./../../../../../data/experiment_pages.json";
import pageMap from "./../../../../../data/pages.json";
import "./course-main.scss";
import { ChevronRightOutlined } from "@material-ui/icons";
import { buildLink, debounce } from "../../../../../utils/functions";
import EB3000Service from "../../../../../services/eb3000-service";
// import Error from "../../../../template/Error";
import HardwareConnector from "./hardware-connector";
import { connect } from "react-redux";
import { showMessage } from "../../../../../redux/notificationActions";

// let EB3000CheckTimer, interval;

const { REACT_APP_EB3000_CHECK_TIME = 10000 } = process.env;

// get only the keys of pages that have questions
const pagesWithoutQuestions = Object.keys(experiment_pages).filter(
  (key) => !experiment_pages[key].has_questions
);

class CourseMain extends React.Component {
  /**
   * Debounced function to check element visibility
   */
  debouncedCheckVisible = debounce((el, callback) => {
    if (this.checkVisible(el)) {
      // console.debug("debouncedCheckVisible: element is visible:\n", el);
      callback();
    }
  }, 100);

  constructor(props) {
    super(props);
    this.state = {
      experiments: null,
      pages: {},
      courseProgress: null,
      current_experiment_index: null,
      current_experiment_id: null,
      current_page: null,
      selected_chapter: null,
      showButton: false,
      linkToNext: false,
      currentPageIndex: 0,
      routePageIndex: 0,
      pageRedirect: null,
      pageIsDone: false,
      lastExperiment: false,
      error: null,
      next_experiment_index: null,
      btnVisible: false,
      subDivisions: [
        {
          title: "Objectives",
          id: "objectives",
          index: ["html_objectives_equipment"], // ['html_objectives', 'html_equipment'],
        },
        { title: "Discussion", id: "discussion", index: ["html_discussion"] },
        { title: "Preparation Questions", id: "prep", index: ["html_prep"] },
        { title: "Experiment", id: "experiment", index: ["html_experiment"] },
        {
          title: "Summary Questions",
          id: "summary",
          index: ["html_questions"],
        },
      ],
      EB3000IsConnected: null,
      updatingPageWithNoQuestions: false,
    };
    this.pageScrolledToBottom = false;
    this._proceedButtonRef = null;
    this._accordionElement = null;

    this.setBtnRef = (el) => {
      this._proceedButtonRef = el;
    };
    
    this.setAccordionRef = (el) => {
      this._accordionElement = el;
    };
  }

  async componentDidMount() {
    this._proceedButtonWasShown = false;
    await this.initCourseData();

    // this.proceedIfPageHasNoQuestions(); // open proceed btn in  objectives and discussions right away
    // this.handleScroll();

    // this.pageScrolledToBottom = false;
    // window.addEventListener('scroll', this.handleScroll.bind(this));
    window.addEventListener(
      "scroll",
      this.handleProceedButtonVisibility.bind(this)
    );

    window.addEventListener(
      "scroll",
      this.updateAccordionHeight.bind(this)
    );
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll.bind(this));

    window.removeEventListener(
      "scroll",
      this.handleProceedButtonVisibility.bind(this)
    );

    window.removeEventListener(
      "scroll",
      this.updateAccordionHeight.bind(this)
    );

    // this.stopCheckingEB3000Connection();
  }

  updateAccordionHeight() {
    const course = document.getElementById("course-main");
    if( !this._accordionElement ) {
      console.debug("updateAccordionHeight: no element defined: ", this._accordionElement)
      return;
    }
    if( !course ) {
      console.debug("updateAccordionHeight: no course defined: ", course)
      return;
    }

    try{
      const threshold = 40 ;
      const padding = 10;
      const viewportTop = document.documentElement.scrollTop;
      const containerY = course.getBoundingClientRect().top;

      console.debug("updateAccordionHeight container y:", containerY)
      console.debug("updateAccordionHeight viweport y:", viewportTop)

      // update scrolled class of the container 
      if( viewportTop > threshold ) 
        course.classList.add("scrolled")
      else 
        course.classList.remove("scrolled")

      // element top position
      // const y = this._accordionElement.getBoundingClientRect().top;

      // viewport height
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

      // update element top position
      const top = containerY > 0 ? 0 : - containerY + padding;
      this._accordionElement.style.top = top  + "px";

      // element target height is viewport top position plus viewport height minus element top position
      // const height = containerY > 0 ?  vh - containerY - threshold - padding : vh - threshold - padding;
      const height = containerY > 0 ?  vh - containerY - threshold  : vh - threshold ;
      console.debug("updateAccordionHeight to", height, "top position to", top );
      this._accordionElement.style.height = height + "px";

      

    } catch(e){
      console.debug("updateAccordionHeight error:", e);
    }
  }

  async componentDidUpdate(prevProps) {
    console.log('CourseMain componentDidUpdate - prevProps: ', prevProps);
    //console.log('CourseMain componentDidUpdate - prevState: ', prevState);
    const courseHasChanged = this.props.course !== prevProps.course;
    const experimentHasChanged =
      this.props.exp_id && this.props.exp_id !== prevProps.exp_id;
    const chapterHasChanged = this.props.chapter !== prevProps.chapter;
    const languageHasChanged = prevProps.language !== this.props.language;

    console.debug(
      "componentDidUpdate: \ncourseHasChanged",
      courseHasChanged,
      "\nexperimentHasChanged",
      experimentHasChanged,
      "\nchapterHasChanged",
      chapterHasChanged,
      "chapter:",
      this.props.chapter,
      ", old chapter:",
      prevProps.chapter
    );

    // calling one of them in order : course/experiment/page .
    // ech one is called only if previous ones weren't called
    if (courseHasChanged || languageHasChanged) {
      await this.initCourseData();
    } else if (experimentHasChanged) {
      this.initExperimentData(this.props.exp_id);
      // await this.fetchExperimentProgress(this.props.exp_id);
    } else if (chapterHasChanged) {
      this.initPage();
    }
  }

  /**
   * Prepares and stores to state course-related data, then triggers experiment and page inits.
   * @returns
   */
  async initCourseData() {
    console.debug("initCourseData started. props: ", this.props);
    const { course_id } = this.props.course;
    const { exp_id } = this.props;
    if (!course_id) return;

    // TODO: separate experiments fetch from progress fetch!
    // TODO: progress fetch happens often and experiments are large.
    const experiments = await this.fetchExperiments(course_id);

    const courseProgress = await ProgressService.getCourseProgress(course_id);

    if (courseProgress) {
      // store course-related values to state
      this.storeCourseToState(exp_id, courseProgress, experiments);
    }
  }

  /**
   * Prepares and stores state values for a newly selected experiment, then triggers initExperimentData
   * @param {*} exp_id - experiment ID from URL
   * @param {*} current_experiment_id - current experiment ID from course progress
   * @param {*} experiments - array of experiments from course
   */
  storeCourseToState(exp_id, courseProgress, experiments) {
    console.debug(
      "storeCourseToState called with",
      exp_id,
      courseProgress,
      experiments
    );
    let current_experiment_index, pageRedirect;

    let { current_experiment_id } = courseProgress;

    // cast to integer if not empty
    current_experiment_id = current_experiment_id
      ? parseInt(current_experiment_id)
      : null;

    // calculate current experiment index, if possible
    if (experiments && current_experiment_id) {
      current_experiment_index =
        experiments.findIndex(
          (e) => e.course_experiment_id === current_experiment_id
        ) ||
        // in case ot wasn't found - make it null
        null;
    }

    // no experiment selected - let's redirect to current experiment or first one in course if none
    if (!exp_id) {
      const { organization, prog_id, course } = this.props;

      pageRedirect = buildLink({
        organization,
        program: prog_id,
        course: course.course_id,
        slug: "experiment",
        // either current experiment id, or - if not set yet - first experiment's id
        experiment:
          current_experiment_id || experiments[0].course_experiment_id,
          // first page
        chapter: pageMap[0]
      });
    }

    // set corse-related state variables
    this.setState(
      {
        current_experiment_id,
        current_experiment_index,
        experiments,
        courseProgress,
        pageRedirect,
      },
      async () => {
        // only proceed with other details if exp_id is set
        if (exp_id) {
          // init experiment data
          await this.initExperimentData(exp_id);
        }
      }
    );
  }

  /**
   * Fetches and stores in state experiment data then triggers page data update
   * @param {*} exp_id - experiment ID from URL
   */
  async initExperimentData(exp_id) {
    console.debug("initExperimentData called");
    await this.fetchExperimentProgress(exp_id);
    // await this.initPage();
  }

  async initPage() {
    console.debug("initPage called");
    // this.pageScrolledToBottom = false;

    this.prepareRedirect(() => {
      // deal with button visibility
      this._proceedButtonWasShown = false;
      this.handleProceedButtonVisibility();

      // await this.proceedIfPageHasNoQuestions();
    });
  }

  /**
   * # prepareRedirect(callback)
   * Prepares `linkToNext`, `pageIsDone` and `selected_chapter`, values for state:
   * - `pageRedirect` null or string - is redirection link for immediate redirect
   * - `pageIsDone` boolean, defines whether the currently selected page is done
   * - `selected_chapter` string - URL-selected chapter (page). Render checks this value against props, if they differ, it means the state wasn't updated yet so a Spinner is shown
   * Gets the current page according to DB and URL-selected chapter and compares them.
   * If selected chapter is after current page - prepares redirect to current page.
   * Prepares link to next page and stores the pageIsDone boolean flag.
   * @param {*} callback - callback to call when state is updated
   * @returns
   */
  prepareRedirect(callback) {
    console.debug("prepareRedirect is called");
    const {
      // this is set within storeCourseToState() method
      // last enabled experiment within course according to course progress
      current_experiment_id,

      // These two values are set in fetchExperimentProgress() method:
      // last enabled page within experiment according to experiment progress data
      current_page,
      // pages progress data
      pages,
    } = this.state;

    const { exp_id, organization, prog_id, course } = this.props;

    let pageRedirect;

    // this is the chapter selected by URL
    let { chapter: selected_chapter } = this.props;

    // don't run this before vital data is not set
    if (!current_page || !current_experiment_id || !course) {
      console.debug(
        "prepareRedirect: no current page, experiment id or course data\n",
        current_page,
        current_experiment_id,
        course
      );
      return;
    }

    // if no experiment id is selected, redirect to first page of current experiment
    if (!exp_id) {
      pageRedirect = buildLink({
        organization,
        program: prog_id,
        course: course.course_id,
        slug: "experiment",
        experiment: current_experiment_id,
        // chapter: current_page || pageMap[0],
        chapter: pageMap[0],
      });

      console.debug(
        "prepareRedirect: no exp_id, redirecting to \n",
        pageRedirect
      );

      // set redirect and go away
      this.setState(
        { selected_chapter, pageRedirect },
        () => typeof callback === "function" && callback()
      );
      return;
    }

    // experiment is selected, let's deal with chapter (page)

    // if no valid chapter selected - let's jump to the first chapter
    if (!pageMap.includes(selected_chapter)) {
      // if no chapter, go to current page or first page
      pageRedirect = buildLink({
        organization,
        program: prog_id,
        course: course.course_id,
        slug: "experiment",
        experiment: exp_id,
        chapter: pageMap[0],
      });

      console.debug(
        "prepareRedirect: no chapter selected, redirecting to \n",
        pageRedirect
      );

      // set redirect and go away
      this.setState(
        { selected_chapter, pageRedirect },
        () => typeof callback === "function" && callback()
      );
      return;
    }

    // we have selected chapter, let's compare its position with last enabled page
    const routePageIndex = pageMap.findIndex((p) => p === selected_chapter);
    const currentPageIndex = pageMap.findIndex((p) => p === current_page);

    // If URL-selected index is larger than current page (page is not yet available) - redirect to current page
    if (routePageIndex > currentPageIndex) {
      // if no chapter, go to current page or first page
      pageRedirect = buildLink({
        organization,
        program: prog_id,
        course: course.course_id,
        slug: "experiment",
        experiment: exp_id,
        chapter: current_page || pageMap[0],
      });

      // set redirect and go away
      this.setState(
        { selected_chapter, pageRedirect },
        () => typeof callback === "function" && callback()
      );
      return;
    }

    const linkToNext = this.prepareLinkToNext(routePageIndex, currentPageIndex);
    const pageIsDone = pages ? pages[selected_chapter] === "done" : false;

    console.debug(
      "prepareRedirect: everything's good, setting linkToNext, pageIsDone, selected_chapter:\n",
      linkToNext,
      pageIsDone,
      selected_chapter
    );

    this.setState(
      {
        // routePageIndex,
        // currentPageIndex,

        // set this to null
        pageRedirect,
        linkToNext,
        pageIsDone,
        selected_chapter,
      },
      () => typeof callback === "function" && callback()
    );
  }

  // calc_next_exp_index() {
  //   const { experiments } = this.state;
  //   const { exp_id } = this.props;

  //   // it is -1 if no experiments loaded or no experiment selected
  //   if (!experiments || !exp_id) return -1;

  //   let index = null;
  //   experiments.map((exp, ind) => {
  //     if (parseInt(exp.course_experiment_id) === parseInt(exp_id)) {
  //       index = ind;
  //     }
  //   });
  //   return index + 1;
  // }

  // calc_curr_exp_ind(experiments, current_experiment_id) {
  //   current_experiment_id = parseInt(current_experiment_id);
  //   experiments.map((exp, ind) => {
  //     if (exp.course_experiment_id === current_experiment_id) {
  //       this.setState({ current_experiment_index: ind });
  //     }
  //   });
  // }

  async fetchExperiments(id) {
    // don't need subscription here...
    // const experiments = await CourseService.getCourseExperiments(id, () =>
    //   this.fetchExperiments(id)
    // );
    return await CourseService.getCourseExperiments(id);
  }

  /**
   * Check whether element is within viewport
   * @param {*} elm DOM element
   * @returns {boolean}
   */
  checkVisible(elm) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    // console.debug(
    //   "checkVisible rect:",
    //   rect,
    //   "\nelm:",
    //   elm,
    //   "\nviewHeight:",
    //   viewHeight
    // );
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  /**
   * Checks whether the Proceed button is within viewport,
   * and if it is and the page has no questions and is not yet done -
   * sends request to server to open next page
   */
  handleProceedButtonVisibility() {
    if (!this._proceedButtonWasShown) {
      if (this._proceedButtonRef)
        // cal debounced function with callback that runs proceedIfPageHasNoQuestions() in case of button visible
        this.debouncedCheckVisible(this._proceedButtonRef, () => {
          // set 'button shown' flag to true
          this._proceedButtonWasShown = true;
          console.debug(
            "handleProceedButtonVisibility: calling proceedIfPageHasNoQuestions()"
          );
          // this is the only place this function is called
          this.proceedIfPageHasNoQuestions();
        });
    }
  }

  // handleScroll() {
  //   const windowHeight =
  //     'innerHeight' in window
  //       ? window.innerHeight
  //       : document.documentElement.offsetHeight;
  //   const body = document.body;
  //   const html = document.documentElement;
  //   const docHeight = Math.max(
  //     body.scrollHeight,
  //     body.offsetHeight,
  //     html.clientHeight,
  //     html.scrollHeight,
  //     html.offsetHeight
  //   );
  //   const windowBottom = windowHeight + window.pageYOffset;

  //   if (windowBottom + 5 >= docHeight) {
  //     if (!this.pageScrolledToBottom) {
  //       this.proceedIfPageHasNoQuestions();
  //       this.pageScrolledToBottom = true;
  //     }
  //   }
  // }

  async autofinishPage() {
    console.debug("autofinishing page");

    const { chapter, exp_id, onShowMessage } = this.props;
    const flags = await ProgressService.autofinishPage(exp_id, chapter);
    if (!flags) {
      onShowMessage(ProgressService.error, "error");
      return;
    }

    // extract flags
    const { courseStatusChange, experimentStatusChanged, pageStatusChanged } =
      flags;

    // call flags handler callback
    this.flagsHandler(
      courseStatusChange,
      experimentStatusChanged,
      pageStatusChanged
    );

    // repopulate and rerender
    // await this.populateState();
  }

  async resetPageProgress() {
    const { onShowMessage } = this.props;
    const {
      current_experiment_id: exp_id,
      current_page: chapter,
      // pageIsDone
    } = this.state;

    console.debug(
      "resetting page progress for page",
      chapter,
      "of experiment",
      exp_id
    );
    // const { chapter, exp_id } = this.props;

    const flags = await ProgressService.resetPageProgress(exp_id, chapter);
    if (!flags) {
      onShowMessage(ProgressService.error, "error");
      return;
    }
    // extract flags
    const { courseStatusChange, experimentStatusChanged, pageStatusChanged } =
      flags;

    // call flags handler callback
    this.flagsHandler(
      courseStatusChange,
      experimentStatusChanged,
      pageStatusChanged
    );
  }

  async flagsHandler(
    courseStatusChange,
    experimentStatusChanged,
    pageStatusChanged
  ) {
    const { current_experiment_id } = this.state;

    console.debug(
      "flagsHandler",
      courseStatusChange,
      experimentStatusChanged,
      pageStatusChanged
    );

    if (experimentStatusChanged || courseStatusChange) {
      await this.initCourseData();
    } else if (pageStatusChanged && current_experiment_id) {
      await this.fetchExperimentProgress(current_experiment_id);
    }
  }

  /**
   * Fetches current page and pages progress data from server and stores them to state.
   * After storing to state, triggers preparePageIndexes()
   * @param {*} exp_id
   */
  async fetchExperimentProgress(exp_id) {
    const result = await ProgressService.getExperimentProgress(exp_id);
    let { pages, current_page } = result;
    //console.log('PAGES: ', pages);
    //console.log('current_page: ', current_page);
    // this.setState({ current_page, pages }, () => this.prepareRedirect());
    this.setState({ current_page, pages }, () => this.initPage());
  }

  /**
   * if current page has no questions on it, update it as done and enable next button.
   */
  async proceedIfPageHasNoQuestions() {
    console.debug("proceedIfPageHasNoQuestions called");
    const { current_experiment_id, current_page, pageIsDone } = this.state;
    const { onShowMessage } = this.props;

    console.debug(
      "proceedIfPageHasNoQuestions current_experiment_id, current_page, pageIsDone:\n",
      current_experiment_id,
      current_page,
      pageIsDone
    );

    // const pagesWithoutQuestions = ["discussion", "objectives"];
    console.debug("pagesWithoutQuestions", pagesWithoutQuestions);

    if (
      current_experiment_id &&
      current_page &&
      pagesWithoutQuestions.includes(current_page) &&
      !pageIsDone
    ) {
      // turn button scroller off
      this.setState({ updatingPageWithNoQuestions: true }, async () => {
        console.debug("proceedIfPageHasNoQuestions: sending proceed request");
        const result = await ProgressService.updateAnswers(
          current_experiment_id,
          current_page,
          {},
          // all pages without questions DO NOT REQUIRE EB3000 connection, so FALSE here
          false
        );

        if (!result) {
          onShowMessage(ProgressService.error, "error");
          return;
        }
        // turn button scroller off
        this.setState({ updatingPageWithNoQuestions: false });

        const {
          courseStatusChange,
          experimentStatusChanged,
          pageStatusChanged,
        } = result;

        this.flagsHandler(
          courseStatusChange,
          experimentStatusChanged,
          pageStatusChanged
        );
      });
    } else {
      console.debug(
        "proceedIfPageHasNoQuestions: page has questions or is done"
      );
    }
  }

  /**
   * Prepares link for the "Next" button at the bottom of the page.
   * @param {*} routePageIndex
   * @param {*} currentPageIndex
   * @returns
   */
  prepareLinkToNext = (routePageIndex, currentPageIndex) => {
    const { current_experiment_id } = this.state;
    const { organization, prog_id, exp_id } = this.props;
    const { course_id } = this.props.course;

    // do not prepare link if route page is after current page - it won't be rendered anyway
    if (currentPageIndex < routePageIndex && exp_id === current_experiment_id) {
      return false;
    }

    const nextPage = pageMap[routePageIndex + 1]
      ? pageMap[routePageIndex + 1]
      : false;

    // get next experiment id
    const nextExpId = this.getNextExperimentId(exp_id);

    // if nextExpId is FALSE, this is the last experiment in course.
    this.setState({ lastExperiment: nextExpId === false });

    // get first chapter from pageMap (it's objectives, but maybe that'll change)
    const [firstPage] = pageMap;

    // const nextExperimentLink = `/program/${prog_id}/course/${course_id}/experiment/${nextExpId}/${firstPage}`;
    const nextExperimentLink = buildLink({
      organization,
      program: prog_id,
      course: course_id,
      slug: "experiment",
      experiment: nextExpId,
      chapter: firstPage,
    });

    // const nextPageLink = `/program/${prog_id}/course/${course_id}/experiment/${current_experiment_id}/${nextPage}`;
    const nextPageLink = buildLink({
      organization,
      program: prog_id,
      course: course_id,
      slug: "experiment",
      experiment: current_experiment_id,
      chapter: nextPage,
    });

    // if next page exists - it's the next page link, otherwise - next experiment link
    return nextPage ? nextPageLink : nextExperimentLink;
  };

  /**
   * Returns next experiment's id.
   * If given experiment is the last one in course, returns FALSE
   * If no experiments are loaded yet, returns NULL
   * @param {*} exp_id
   * @returns
   */
  getNextExperimentId(exp_id) {
    exp_id = parseInt(exp_id); // to match format

    const { experiments } = this.state;
    // if no experiments - return null
    if (!experiments) return null;

    // get experiment index
    const expIndex = experiments.findIndex(
      (e) => e.course_experiment_id === exp_id
    );

    // if it's the last one - return false
    if (expIndex === experiments.length - 1) return false;

    // oherwise, return next item's id
    return experiments[expIndex + 1].course_experiment_id;
  }

  markup = (html) => {
    return { __html: html };
  };

  render() {
    console.debug("CourseMain render", this.props);
    // console.debug("CourseMain props", this.props);

    let {
      organization,
      chapter,
      course,
      exp_id,
      prog_id,
      user,
      allowNoHardwareExperiment,
      language
    } = this.props;

    const userIsTester =
      user &&
      Array.isArray(user.credentials) &&
      user.credentials.includes("tester");

    const {
      experiments,
      courseProgress,
      current_experiment_id,
      subDivisions,
      current_experiment_index,
      current_page,
      // currentPageIndex,
      // routePageIndex,

      pageRedirect,
      selected_chapter,

      linkToNext,
      lastExperiment,
      pageIsDone,
      // EB3000IsConnected,
      // error,
      updatingPageWithNoQuestions,
    } = this.state;

    // Redirection logic:
    // If pageRedirect is set - redirect there .
    if (pageRedirect) {
      console.debug("Course main render - redirecting to ", pageRedirect);
      return <Redirect replace to={pageRedirect} />;
    }

    // wait for course progress to load and page cha
    if (
      !organization ||
      !course.course_id ||
      !current_experiment_id ||
      !current_page ||
      !experiments ||
      !course ||
      // check that chapter in URL and selected chapter are the same.
      // This won't be so when navigating to another page or coming in for the first time - until the state is updated.
      chapter !== selected_chapter
    ) {
      return <Spinner />;
    }


    console.debug("CourseMain render - chapter OK:", chapter);


    const experimentsList = (
      <List>
        {experiments.map((exp, ind) => {
          return (
            <ListItem
              button={true}
              divider={true}
              disabled={ind > current_experiment_index}
              selected={exp.course_experiment_id === exp_id}
              key={exp.course_experiment_id}
            >
              <ExpAccordion
                key={`accordion-${exp.course_experiment_id}`}
                experiment_link={
                  buildLink({
                    organization,
                    program: prog_id,
                    course: course.course_id,
                    slug: "experiment",
                    experiment: exp.course_experiment_id,
                  })
                }
                organization={organization}
                exp={exp}
                subDiv={subDivisions}
                course_id={course.course_id}
                currentPage={current_page}
                chapter={chapter}
                exp_id={parseInt(exp_id)}
                prog_id={parseInt(prog_id)}
              />
            </ListItem>
          );
        })}
      </List>
    );

    console.debug("CourseMain render - rendering HTML");

    return (
      <div className="course-main-container" id="course-main">
        {this.state.error ? (
          <Typography className="error-message">{this.state.error}</Typography>
        ) : (
          <Grid container spacing={3}>
            {/* ----------------------experiment tabs submenu------------------------------ */}
            <Grid item xs={12} md={3} className="course-accordion-block">
              <div className="exp-list-container" id="accordion-container" 
              ref={this.setAccordionRef}
              >{experimentsList}</div>
            </Grid>
            {/* ----------------------experiment content------------------------------------ */}
            <Grid item xs={12} md={9}>
              <div className="exp-content-container">
                {experiments && exp_id && chapter ? (
                  <>
                    <HardwareConnector
                      // hardware connection check is only active within experiment page
                      // and is not active if the user is tester and allowNoHardwareExperiment is enabled
                      active={
                        chapter === "experiment" &&
                        !pageIsDone &&
                        !(userIsTester && allowNoHardwareExperiment)
                      }
                      connectionChecker={EB3000Service.checkIfConnected}
                      checkDelay={REACT_APP_EB3000_CHECK_TIME}
                    >
                      <ExperimentCycle
                        {...{
                          user,
                          language,
                          allowNoHardwareExperiment,
                          userIsTester,
                          organization,
                          prog_id,
                          course_id: course.course_id,
                          chapter,
                          // exp_id: current_experiment_id,
                          exp_id,
                          // pass pageIsDone to cycle in order to block controls on finished pages
                          pageIsDone,
                          // pass reset page handler
                          resetPageProgress: this.resetPageProgress.bind(this),
                          autofinishPage: this.autofinishPage.bind(this),
                          // pass current page for usage in tester tools
                          current_page,
                          // pass flags handler
                          flagsHandler: this.flagsHandler.bind(this),
                          subDivisions,
                          experiments,
                          fetchExperiments: () =>
                            this.fetchExperiments(course.course_id),
                        }}
                      />
                    </HardwareConnector>

                    <div className="bottom-buttons">
                      {
                        // last page in experiment
                        chapter === "summary" &&
                        pageIsDone &&
                        !lastExperiment ? (
                          <>
                            {linkToNext && (
                              <Button
                                component={Link}
                                to={linkToNext}
                                variant="contained"
                                color="primary"
                                disabled={!pageIsDone}
                              >
                                <ChevronRightOutlined />{" "}
                                {"Go to next experiment"}
                              </Button>
                            )}
                            <Button
                              component={Link}
                              // to={`/program/${prog_id}/course/${course.course_id}/progress`}
                              to={buildLink({
                                organization,
                                program: prog_id,
                                course: course.course_id,
                                slug: "progress",
                              })}
                              variant="contained"
                              color="secondary"
                              disabled={!pageIsDone}
                            >
                              {"View Progress"}
                            </Button>
                          </>
                        ) : lastExperiment && chapter === "summary" ? (
                          <>
                            {courseProgress &&
                              courseProgress.status === "done" && (
                                <div className="vertical">
                                  <Typography
                                    color="secondary"
                                    variant="h4"
                                    align="center"
                                  >
                                    Congratulations!
                                  </Typography>
                                  <Typography variant="body1" align="center">
                                    You have finished this course.
                                  </Typography>
                                  <Button
                                    component={Link}
                                    // to={`/program/${prog_id}/course/${course.course_id}/progress`}
                                    to={buildLink({
                                      organization,
                                      program: prog_id,
                                      course: course.course_id,
                                      slug: "progress",
                                    })}
                                    variant="contained"
                                    color="secondary"
                                    disabled={!pageIsDone}
                                  >
                                    {"See your results"}
                                  </Button>
                                </div>
                              )}
                          </>
                        ) : (
                          <Button
                            component={Link}
                            to={linkToNext}
                            variant="contained"
                            color="primary"
                            disabled={!pageIsDone}
                            ref={this.setBtnRef}
                          >
                            Next
                            {
                              // if updating page withoutquestions, show progress icon
                              updatingPageWithNoQuestions ? (
                                <CircularProgress
                                  style={{
                                    width: "1em",
                                    height: "1em",
                                    color: "rgba(0,0,0,0.5)",
                                  }}
                                />
                              ) : (
                                <ChevronRightOutlined />
                              )
                            }
                          </Button>
                        )
                      }
                    </div>
                  </>
                ) : null}
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const stateToProps = (state) => {
  const { user, allowNoHardwareExperiment, language } = state.root;

  return { user, allowNoHardwareExperiment, language };
};

const mapDispatchToProps = (dispatch) => ({  
    onShowMessage: (message, type) => dispatch( showMessage(message, type)),
})



export default connect (stateToProps, mapDispatchToProps)(CourseMain);
// export default CourseMain;
