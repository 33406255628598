import Base from './baseClientService';

class ProgramService extends Base {
  static async getPrograms() {
    try {
      const items = await this.GET(`/student/programs`);

      return items;
    } catch (e) {
      console.error('Error fetching programs from server: ', e.message);
      return false;
    }
  }
}
Base.extend(ProgramService);

export default ProgramService;
