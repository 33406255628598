import Base from "./baseClientService";
import store from "../redux/store";
import { buildLink } from "../utils/functions";

class OrganizationsService extends Base {
  static async getUserOrganizations() {
    try {
      const { user } = store.getState().root;
      if (!user) throw new Error("User not logged in");
      const { organizations } = user;
      // console.debug("user organizations:", organizations)
      if (!Array.isArray(organizations))
        throw new Error(
          "Error in user data. Please try logging out and back in."
        );
      return organizations;
    } catch (e) {
      return this.Error(
        e.message || "Unknown error. Please, try reloading page."
      );
    }

    // const cacheIndex = "user_organizations"

    // return await this.getCachedOr( cacheIndex , () => {
    //   try{
    //     const {user} = store.getState().root;
    //     if( !user) throw new Error( "User not logged in" )
    //     const {organizations} = user;
    //     // console.debug("user organizations:", organizations)
    //     if( !Array.isArray(organizations)) throw new Error("Error in user data. Please try logging out and back in.")

    //     this.cache( cacheIndex, organizations )
    //     return organizations;
    //   }catch(e){
    //     return this.Error(e.message || "Unknown error. Please, try reloading page.")
    //   }
    // })
  }

  /**
   * Returns whether current user has organization with given slug.
   * returns
   *
   * @param {*} organization_slug
   * @returns {boolean|null} true/false as an answer, null in case of error
   */
  static async userHasOrganizationSlug(organization_slug) {
    console.debug("userHasOrganizationSlug called with", organization_slug);
    const orgs = await this.getUserOrganizations();
    if (!orgs) return null;
    // console.debug("checking if user has slug", organization_slug , "within", orgs.map(o => o.url_slug))
    return orgs.map((o) => o.url_slug).includes(organization_slug);
  }

  static async getFirstOrganization() {
    const organizations = await this.getUserOrganizations();
    // console.debug("organizations:", organizations)
    if (!Array.isArray(organizations) || !organizations[0])
      return this.Error(
        "Error in user data. Please try logging out and back in."
      );
    return organizations[0];
  }

  static async getFirstOrganizationLink() {
    const firstOrg = await this.getFirstOrganization();
    if (!firstOrg) return false;

    return buildLink({
      organization: firstOrg.url_slug,
    });
  }
}
Base.extend(OrganizationsService);

export default OrganizationsService;
