import React from "react";
import { connect } from "react-redux";
import ProgressService from "../../../../../services/progress";
import Parser from "../../../../../shared-components/Parser";
import HardwareConnector from "../CourseMain/hardware-connector";
import EB3000Service from "../../../../../services/eb3000-service";
import experiment_pages from "../../../../../data/experiment_pages.json";
import {
  ChevronLeftOutlined,
  ChevronRightOutlined,
  Delete,
  SkipNext,
} from "@material-ui/icons";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import AsyncButton from "../../../../../shared-components/Parser/AsyncButton";
import { Link } from "react-router-dom";
import { buildLink, debounce } from "../../../../../utils/functions";
import Spinner from "../../../../template/spinner";
import AuthService from "../../../../../services/AuthService";
// import pageMap from "./../../../../../data/pages.json";

const { REACT_APP_EB3000_CHECK_TIME = 10000 } = process.env;

class ExperimentContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      html: null,
      parserErrors: null,
      questions: null,
      page_answers: null,
      updatingPageWithNoQuestions: false,
    };
    this._proceedButtonRef = null;
    this.setBtnRef = (el) => {
      this._proceedButtonRef = el;
    };
  }
  debouncedCheckVisible = debounce((el, callback) => {
    if (this.checkVisible(el)) {
      // console.debug("debouncedCheckVisible: element is visible:\n", el);
      callback();
    }
  }, 100);

  componentDidMount() {
    this.props.fetchTranslations([
      "See your results",
      "Previous",
      "Next",
      "Go to next experiment",
      "View progress",
      "Congratulations!",
      "You have finished this course.",
    ]);
    this._proceedButtonWasShown = false;
    this.fetchHtml();
    window.addEventListener("scroll", this.handleProceedButtonVisibility);
    this.handleProceedButtonVisibility();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleProceedButtonVisibility);
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.chapter !== prevProps.chapter ||
      this.props.currentExperiment !== prevProps.currentExperiment ||
      this.props.course !== prevProps.course
    ) {
      this._proceedButtonWasShown = false;
      await this.fetchHtml();
      this.handleProceedButtonVisibility();
    }
  }
  fetchHtml = async () => {
    const { currentExperiment, chapter } = this.props;
    // import ProgressService to component
    let html = "";
    experiment_pages[chapter] &&
      experiment_pages[chapter]["index"].map(
        (page) => (html = html + currentExperiment[page])
      );
    const current_experiment_id = currentExperiment.course_experiment_id;
    const page_answers = await ProgressService.getPageAnswers(
      // id of current experiment
      current_experiment_id,
      chapter
    );
    // extract only needed questions from current experiment questions
    let questions = [];
    if (page_answers) {
      // gather question ids
      const pageQuestionsIds = Object.keys(page_answers).map((i) =>
        parseInt(i)
      );
      questions = currentExperiment.questions.filter((q) =>
        pageQuestionsIds.includes(q.question_id)
      );
    }
    this.setState({ questions, page_answers, html });
  };
  handleProceedButtonVisibility = () => {
    const { chapter, onPageUpdate, experimentProgress } = this.props;
    // page is done if chapter is done in pageProgress
    const pageIsDone =
      experimentProgress &&
      experimentProgress.pages &&
      experimentProgress.pages[chapter] === "done";
    console.log("handleProceedButtonVisibility", this._proceedButtonRef);
    if (!this._proceedButtonWasShown && this._proceedButtonRef) {
      this.debouncedCheckVisible(this._proceedButtonRef, () => {
        this._proceedButtonWasShown = true;
        console.debug(
          "handleProceedButtonVisibility: calling proceedIfPageHasNoQuestions()"
        );
        // this is the only place this function is called
        if (
          // if there is such page
          experiment_pages[chapter] &&
          // and if it has no questions
          !experiment_pages[chapter]["has_questions"] &&
          // and if page is done...
          !pageIsDone
        ) {
          // ...then proceed to next page
          // set flag to show spiner in Next button
          this.setState({ updatingPageWithNoQuestions: true }, async () => {
            // update page with no answers
            await onPageUpdate({}, false);
            // turn the spinner off
            this.setState({ updatingPageWithNoQuestions: false });
          });
        }
      });
    }
  };
  checkVisible = (elm) => {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    // console.debug(
    //   "checkVisible rect:",
    //   rect,
    //   "\nelm:",
    //   elm,
    //   "\nviewHeight:",
    //   viewHeight
    // );
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  };
  onQuestionChange = (answersObj) => {
    const {
      user,
      chapter,
      experimentProgress,
      allowNoHardwareExperiment,
      onPageUpdate,
    } = this.props;
    const pageIsDone = experimentProgress.pages[chapter] === "done";
    const userIsTester =
      user &&
      Array.isArray(user.credentials) &&
      user.credentials.includes("tester");
    const checkEB3000 =
      chapter === "experiment" &&
      // also check that if the user is tester - no-hardware experiment is not allowed
      !(userIsTester && allowNoHardwareExperiment);
    if (!pageIsDone) {
      return onPageUpdate(answersObj, checkEB3000) || false;
    }
    return false;
  };
  onParserErrors = (errors) => {
    const { chapter, currentExperiment } = this.props;
    AuthService.reportError(
      `Parsing errors in page '${chapter}' of experiment ${currentExperiment.course_experiment_id} (${currentExperiment.title}) `,
      errors,
      "parser error"
    );
  };
  render() {
    const { questions, page_answers, html, updatingPageWithNoQuestions } =
      this.state;
    const {
      chapter,
      allowNoHardwareExperiment,
      experimentProgress,
      user,
      autofinishPage,
      resetPageProgress,
      courseProgress,
      organization,
      prog_id,
      currentExperiment,
      course,
      next_chapter_link,
      _t,
      prev_chapter_link,
      experiments,
    } = this.props;
    const { course_id } = course;
    // const pageIsDone = experimentProgress.pages[chapter] === "done";
    const pageIsDone =
      experimentProgress &&
      experimentProgress.pages &&
      experimentProgress.pages[chapter] === "done";
    const pageIsCurrent =
      chapter &&
      experimentProgress &&
      experimentProgress.current_page === chapter;
    const userIsTester =
      user &&
      Array.isArray(user.credentials) &&
      user.credentials.includes("tester");
    const pageHasQuestions =
      experiment_pages[chapter] && experiment_pages[chapter]["has_questions"];
    const courseIsDone = courseProgress && courseProgress.status === "done";
    const isLastChapter = chapter === "summary";
    const isCurrentExperiment =
      courseProgress &&
      courseProgress.current_experiment_id ===
        currentExperiment.course_experiment_id;
    const isLastExperiment =
      experiments.findIndex(
        (exp) =>
          exp.course_experiment_id === currentExperiment.course_experiment_id
      ) ===
      experiments.length - 1;
    const progress_link = buildLink({
      organization,
      program: prog_id,
      course: course_id,
      slug: "progress",
    });
    console.log("experimentContent", this.props, this.state);
    return (
      <HardwareConnector
        // hardware connection check is only active within experiment page
        // and is not active if the user is tester and allowNoHardwareExperiment is enabled
        active={
          chapter === "experiment" &&
          // pageIsDone is whether current page's progress status is "done"
          !pageIsDone &&
          // userIsTester - whether user's credentials include 'tester'
          !(userIsTester && allowNoHardwareExperiment)
        }
        //   include EB3000Service in component
        connectionChecker={EB3000Service.checkIfConnected}
        // get  REACT_APP_EB3000_CHECK_TIME from process.env, like const {REACT_APP_EB3000_CHECK_TIME} = process.env
        checkDelay={REACT_APP_EB3000_CHECK_TIME}
      >
        {userIsTester && pageIsCurrent && isCurrentExperiment && (
          <div className="tester-tools">
            {pageHasQuestions &&
              // page is not done yet
              pageIsCurrent &&
              !pageIsDone && (
                <AsyncButton
                  variant="contained"
                  color="primary"
                  size="small"
                  icon={<SkipNext />}
                  // onClick={this.autofinishPage.bind(this)}
                  onClick={autofinishPage}
                >
                  Auto-finish page
                </AsyncButton>
              )}
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<Delete />}
              // onClick={this.resetPageProgress.bind(this)}
              onClick={resetPageProgress}
            >
              reset page
            </Button>
          </div>
        )}
        {!html && <Spinner />}
        {html && (
          <Parser
            html={html}
            // add onQuestionChange handler to component
            onQuestionChange={this.onQuestionChange}
            questions={page_answers}
            allQuestions={questions}
            // add onParserErrors handler to component
            onErrors={this.onParserErrors}
          />
        )}
        {!(courseIsDone && isLastExperiment && isLastChapter) && (
          <div className="bottom-buttons">
            {prev_chapter_link && (
              <Button
                component={Link}
                to={prev_chapter_link}
                variant="outlined"
                color="primary"
                // style={{ marginRight: "1em", marginLeft: "1em" }}
              >
                <ChevronLeftOutlined />
                {_t("Previous")}
              </Button>
            )}
            <Button
              component={Link}
              disabled={!pageIsDone}
              to={next_chapter_link}
              ref={this.setBtnRef}
              variant="contained"
              color="primary"
              // style={{ marginRight: "1em", marginLeft: "1em" }}
            >
              {pageIsDone && isLastChapter ? (
                <>
                  <ChevronRightOutlined />
                  {_t("Go to next experiment")}
                </>
              ) : (
                <>
                  {_t("Next")}
                  {
                    // if updating page withoutquestions, show progress icon
                    updatingPageWithNoQuestions ? (
                      <CircularProgress
                        style={{
                          width: "1em",
                          height: "1em",
                          color: "rgba(0,0,0,0.5)",
                        }}
                      />
                    ) : (
                      <ChevronRightOutlined />
                    )
                  }
                </>
              )}
            </Button>
            {pageIsDone && isLastChapter && (
              <Button
                component={Link}
                to={progress_link}
                variant="outlined"
                color="secondary"
              >
                {_t("View progress")}
              </Button>
            )}
          </div>
        )}
        {courseIsDone && isLastExperiment && isLastChapter && (
          <div className="vertical">
            <Typography color="secondary" variant="h4" align="center">
              {_t("Congratulations!")}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ marginBottom: "1em" }}
              color="primary"
            >
              {_t("You have finished this course.")}
            </Typography>
            <div className="buttons-bottom">
              {prev_chapter_link && (
                <Button
                  component={Link}
                  to={prev_chapter_link}
                  variant="outlined"
                  // color="primary"
                  // style={{ marginRight: "1em", marginLeft: "1em" }}
                >
                  <ChevronLeftOutlined />
                  {_t("Previous")}
                </Button>
              )}
              <Button
                component={Link}
                to={next_chapter_link}
                variant="contained"
                color="secondary"
                disabled={!pageIsDone}
              >
                {_t("See your results")}
                <ChevronRightOutlined />
              </Button>
            </div>
          </div>
        )}
      </HardwareConnector>
    );
  }
}
const stateToProps = (state) => {
  // allowNoHardwareExperiment is boolean value, determines whether to allow work on experiment without EB3000 connected
  const { user, allowNoHardwareExperiment } = state.root;

  return { user, allowNoHardwareExperiment };
};
export default connect(stateToProps, null)(ExperimentContent);
