import { Container, List, ListItem } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Link } from 'react-router-dom'
import Page404 from '../template/Page404'
import TestParser from './test-parser'


const TestMenu = () => <><h1>Select test</h1>
    <List>
        <ListItem component={Link} to="/test/parser">Parser</ListItem>
    </List>
</>

class TestComponent extends React.Component{

    render(){        
        console.debug("test props", this.props)
        const {user} = this.props;        
        if( !Array.isArray(user.credentials) || ! user.credentials.includes("tester") ) return <Page404 />

        return(<Container className="test">            
            <Routes>
                <Route path="/" element={<TestMenu />}/>                
                <Route path="parser" element={<TestParser/>}/>
            </Routes>            
        </Container>)
    }
}

const stateToProps = (state) => {
    return {
      user: state.root.user      
    };
  };

export default connect( stateToProps )( TestComponent )