import React from "react";
import { Card, CardActionArea, CardContent, Container, Grid } from "@material-ui/core";
import {Link} from 'react-router-dom';
import { Navigate as Redirect } from "react-router-dom";
import OrganizationsService from "../../services/organizations";
import { buildLink } from "../../utils/functions";
import Error from "../template/Error";
import Spinner from "../template/spinner";

class Organizations extends React.Component {
  state = {
    error: null,
    organizations: null,
  };

  componentDidMount(){
      this.fetchOrganizations();
  }
  

  async fetchOrganizations() {
    const organizations = await OrganizationsService.getUserOrganizations();
    this.setState({
        organizations,
        // if organizations weren't fetched - store error
        error: organizations ? null : OrganizationsService.error
    });
  }

  render() {
    const {error, organizations} = this.state;

    // show spinner while loading
    if( organizations === null ) return <Spinner />
    
    // if it's false - it failed to fetch, show error
    if( organizations === false || organizations === undefined || !Array.isArray(organizations) ){ 
        console.error("Wrong organizations array type:", organizations)
        return <Error {...{error}} />
    }

    // if there's only one organization - jump to its page
    if( organizations.length === 1 ){
        const [myOrganization] = organizations;
        const organizationLink = buildLink({ organization: myOrganization.url_slug || myOrganization.organization_id })
        return <Redirect to={organizationLink} />
    }

    // show organizations list
    return (
        <Container className="organizations-list .card-list">
            <Grid container>
                { organizations.map( o => {
                    return <Grid item xs={12}>
                        <Card>
                            <CardActionArea component={Link} to={buildLink({ organization: o.url_slug })}>
                                <CardContent>{o.organization_title}</CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                })}
            </Grid>
        </Container>
    );
  }
}

export default Organizations;
