import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  List,
  ListItem,
} from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { buildLink } from "../../../../../utils/functions";
import experiment_pages from "../../../../../data/experiment_pages.json";
import pageMap from "./../../../../../data/pages.json";
import Spinner from "../../../../template/spinner";

class ExperimentList extends React.Component {
  state = {
    selected: -1,
    current_experiment_index: -1,
  };

  _accordionElement = null;

  // listeners to mount and unmount
  listeners = [
    { event: "scroll", listener: this.updateAccordionHeight.bind(this) },
    // resize listener
    { event: "resize", listener: this.updateAccordionHeight.bind(this) },
  ];

  componentDidMount = async () => {
    const { experiments, student_experiment_id } = this.props;
    if (experiments) {
      // console.log("student_experiment_id:",student_experiment_id)
      const current_experiment_index = (experiments || []).findIndex(
        (exp) => exp.course_experiment_id === student_experiment_id
      );
      this.props.fetchTranslations([
        ...Object.values(experiment_pages).map((page) => page["title"]),
      ]);
      this.setState({ current_experiment_index, selected: -1 });
    }

    // add event listeners
    this.listeners.forEach((listener) => {
      window.addEventListener(listener.event, listener.listener);
    });
  };

  componentWillUnmount() {
    // remove event listeners on unmount
    this.listeners.forEach((listener) => {
      window.removeEventListener(listener.event, listener.listener);
    });
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.experiments &&
      (prevProps.exp_id !== this.props.exp_id ||
        prevProps.experiments !== this.props.experiments)
    ) {
      this.props.fetchTranslations([
        ...Object.values(experiment_pages).map((page) => page["title"]),
      ]);
      // console.log("componentDidUpdate",prevProps, this.props);
      const { experiments, student_experiment_id } = this.props;
      // console.log("student_experiment_id:",student_experiment_id)
      const current_experiment_index = (experiments || []).findIndex(
        (exp) => exp.course_experiment_id === student_experiment_id
      );
      this.setState({ current_experiment_index });
    }

    // if (prevProps.chapter !== this.props.chapter) {
    //   this.updateAccordionHeight();
    // }
  };

  // setSelected = (index) => {
  //   console.debug("setSelected is called with", index);
  //   const { selected: prev_selected } = this.state;
  //   const selected = pageMap.findIndex((p) => p === index);
  //   this.setState({ selected }, () => {
  //     if (prev_selected !== selected) {
  //       console.debug("setSelected:", prev_selected, "->", selected);
  //       this.updateAccordionHeight();
  //     }
  //   });
  // };
  pagesHandler = (page) => {
    const { experimentProgress } = this.props;
    const last_chapter = pageMap.findIndex(
      (p) => p === experimentProgress.current_page
    );
    const current_chapter = pageMap.findIndex((p) => p === page);
    return current_chapter > last_chapter;
  };

  /**
   * Keeps accordion menu height in sync with the content.
   * Checks the height of the content on change event
   * @returns
   */
  updateAccordionHeight() {
    console.debug("updateAccordionHeight called");
    const course = document.getElementById("course-main");
    if (!this._accordionElement) {
      console.debug(
        "updateAccordionHeight: no element defined: ",
        this._accordionElement
      );
      return;
    }
    if (!course) {
      console.debug("updateAccordionHeight: no course defined: ", course);
      return;
    }

    try {
      const threshold = 40;
      const padding = 10;
      const viewportTop = document.documentElement.scrollTop;
      const containerY = course.getBoundingClientRect().top;

      console.debug("updateAccordionHeight container y:", containerY);
      console.debug("updateAccordionHeight viweport y:", viewportTop);

      // update scrolled class of the container
      if (viewportTop > threshold) course.classList.add("scrolled");
      else course.classList.remove("scrolled");

      // element top position
      // const y = this._accordionElement.getBoundingClientRect().top;

      // viewport height
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      // update element top position
      const top = containerY > 0 ? 0 : -containerY + padding;
      this._accordionElement.style.top = top + "px";

      // element target height is viewport top position plus viewport height minus element top position
      // const height = containerY > 0 ?  vh - containerY - threshold - padding : vh - threshold - padding;
      const height =
        containerY > 0 ? vh - containerY - threshold : vh - threshold;
      console.debug("updateAccordionHeight to", height, "top position to", top);
      this._accordionElement.style.height = height + "px";
    } catch (e) {
      console.debug("updateAccordionHeight error:", e);
    }
  }

  render() {
    const {
      experiments,
      exp_id,
      chapter,
      organization,
      prog_id,
      course_id,
      _t,
      translationsLoaded,
    } = this.props;
    const { selected, current_experiment_index } = this.state;
    // console.log("experiment_pages",experiment_pages);
    const subDiv = Object.values(experiment_pages);
    return (
      <>
        {!translationsLoaded && <Spinner />}
        {translationsLoaded && (
          <List
            className="exp-list-container"
            // set accordion element to update accordion height
            ref={(el) => {
              this._accordionElement = el;
            }}
          >
            {experiments &&
              experiments.map((exp, ind) => {
                // console.log("exp_id",exp_id);
                const experiment_link = buildLink({
                  organization,
                  program: prog_id,
                  course: course_id,
                  slug: "experiment",
                  experiment: exp.course_experiment_id,
                  chapter: pageMap[0],
                });
                // console.log("experiment disable",exp.title," ",ind," ",current_experiment_index," ",ind > current_experiment_index)
                return (
                  <ListItem
                    button={true}
                    divider={true}
                    disabled={ind > current_experiment_index}
                    selected={exp.course_experiment_id === exp_id}
                    key={exp.course_experiment_id}
                  >
                    <Accordion
                      expanded={exp_id == exp.course_experiment_id}
                      className="accordion-list"
                      onChange={() => this.updateAccordionHeight()}
                    >
                      <Link
                        to={experiment_link}
                        component={NavLink}
                        className="accordion-experiment-title"
                      >
                        <AccordionSummary>{exp.title}</AccordionSummary>
                      </Link>
                      <AccordionDetails>
                        <List component="div" className="chapters">
                          {subDiv.map((item) => {
                            // const chapterLink = `/program/${prog_id}/course/${course_id}/experiment/${exp.course_experiment_id}/${item.id}`;
                            const chapterLink = buildLink({
                              organization,
                              program: prog_id,
                              course: course_id,
                              slug: "experiment",
                              experiment: exp_id,
                              chapter: item.id,
                            });
                            return (
                              <ListItem
                                component={NavLink}
                                to={chapterLink}
                                key={subDiv.indexOf(item)}
                                button={true}
                                divider={true}
                                disabled={this.pagesHandler(item.id)}
                                // onClick={() => {
                                //   this.setSelected(subDiv.indexOf(item));
                                // }}
                                // selected={selected === subDiv.indexOf(item)}
                              >
                                {_t(item.title)}
                              </ListItem>
                            );
                          })}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </ListItem>
                );
              })}
          </List>
        )}
      </>
    );
  }
}
export default ExperimentList;
