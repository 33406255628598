import Base from './baseClientService';
import store from '../redux/store';
import { setLanguage } from '../redux/userActions';

class TranslationService extends Base {
  static separateExistingFromNonexistant(ids) {
    if (!Array.isArray(ids))
      throw new Error('Wrong format sent to translation');

    let existing = [],
      nonexistant = [];

    // check each id for existance in cache
    for (let i = 0; i < ids.length; i++) {
      if (this.cacheExists(this.getIndex(ids[i]))) {
        existing = [...existing, ids[i]];
      } else {
        nonexistant = [...nonexistant, ids[i]];
      }
    }

    return [existing, nonexistant];
  }

  static getTranslationsFromCache(ids) {
    try {
      return ids.reduce(
        (res, id) => ({ ...res, [id]: this.getCached(this.getIndex(id)) }),
        {}
      );
    } catch (e) {
      console.error('getTranslationsFromCache:', e.message);
      return this.Error('Error fetching translations from cache');
    }
  }

  static storeTranlationsToCache(translated) {
    Object.keys(translated).forEach((key) => {
      this.cache( this.getIndex(key), translated[key] );
    });
  }

  static getIndex(id) {    
    return `${this.currentLanguage}-${id}`;
  }

  static async get(ids = []) {

    // this.currentLanguage = store.getState().root.user
    this.currentLanguage = store.getState().root.language || "en"
      // ? store.getState().root.user.interface_language
      // : 'en';

    console.debug('translations.get:', ids);
    try {
      // split in what we already have and wht we haven't yet
      const [have, donthave] = this.separateExistingFromNonexistant(ids);
      let translated = {};
      console.debug('translations.get: have/donthave', have, donthave, "\nlanguage: ", this.currentLanguage, "\ncache:", this._CACHE);

      // fetch what we haven't
      if (Object.keys(donthave).length > 0) {
        translated = await this.GET(`/student/translation`, {
          params: { ids: donthave },
        });
        if (typeof translated !== 'object')
          return this.Error('Wrong type of data received from server');

        // store new translations to cache
        this.storeTranlationsToCache(translated);
      }

      // get from cache what we already have in form of an object
      const existing =
        Object.keys(have).length > 0 ? this.getTranslationsFromCache(have) : {};

      // merge the two into single object and return
      return { ...existing, ...translated };
    } catch (e) {
      console.error('Error fetching translations: ', e.message);
      return this.Error(e.message);
    }
  }

  static async getLanguages(){
    try {
      return await this.GET("/student/translation/languages")
    } catch(e){
      console.debug("getLanguages:", e.message)
      return this.Error(e.message);
    }
  }

  static async setLanguage( language_id){
    try {
      console.debug("TranslationService.setLanguage:", language_id)
      await this.POST(`/student/translation/lang/${language_id}`)      
      console.debug("TranslationService.setLanguage success")
      this.updateLanguageInState( language_id);      
      return true;
    } catch(e){
      console.debug("setLanguage:", e.message)
      return this.Error(e.message || "Unknown setLanguage error" );
    }
  }

  /**
   * Updates language in redux and in translation class
   * @param {*} language_id 
   */
  static updateLanguageInState( language_id){
    console.debug("updateLanguageInState:", language_id)
    // update current language in service (needed for caching purposes)
    this.currentLanguage = language_id;
    console.debug("updateLanguageInState updated language:", this.currentLanguage)
    
    // update redux state
    store.dispatch( {type: this.actionTypes.SET_LANG, payload: language_id} );
  }
}


TranslationService.currentLanguage = "en"

export default TranslationService;
