import React from 'react'
import {AppBar} from '@material-ui/core';
import { Eco, Person } from '@material-ui/icons';
import "./navbar.scss";
import CenterLogo from './CenterLogo';

const defaultAppBarProps = {
    color: "transparent",
    className: "app-bar-component"
}

class Navbar extends React.Component{

    render(){
        const {
            LeftComponent = <Eco color="primary" />, // default logo component
            RightComponent = <Person color="primary" />,
            AppBarProps = {},
            ContainerComponent = props => <div className="app-bar-container">{props.children}</div>,
            MiddleComponent = <CenterLogo />
        } = this.props;
        return<>
            <AppBar color="transparent" {...{...defaultAppBarProps, ...AppBarProps} } >
                <ContainerComponent className="app-bar-container">
                    {LeftComponent}
                    {MiddleComponent}
                    {RightComponent}
                </ContainerComponent>
            </AppBar>
        </>
    }
}

export default Navbar
